body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background: white;
  background-size: 200% 20%;
}

.homeTitle{
  width: 70%;
  display: flex;
  justify-content: center;
  margin: 80px;
  margin-bottom: 135px;
  margin-left: 15%;
  font-family: 'Raleway', sans-serif;
  text-align: center;
}

.contactForm {
  margin-left: 15%;
  margin-top: 30px;
  margin-bottom: 10px;
  max-width: 70%;
  padding: 10px;
  font-family: 'Raleway', sans-serif;
}

.foot {
  display:flex;
  justify-content:space-evenly;
  flex-wrap: wrap;
  width: 100%;
  border-top: 2px solid black;
  /* margin-left: 5%; */
  /* max-width: 90%; */
  padding-top: 20px;
  padding-right: 35px;
  font-family: 'Raleway', sans-serif;
  list-style-type:none;
  background-color: rgb(41, 40, 40);
  color: #edf0f5;
}

.dat {
  color: white;
}

.imp {
  color: white;
}

html {
  height: 100%;
}

.navb{
  border-bottom: black solid 2px;
  position: fixed;
  width: 100%;
  z-index: 1000 !important;
  box-shadow: 0 8px 10px -6px black;
  background: transparent;
  font-family: 'Raleway', sans-serif;

}

.navb.active{
  background: whitesmoke;
}

.navb1 {
  display: flex;
  justify-content: flex-end;
}

.navLogo {
  padding-right: 20px;
  padding-left: 20px;  
}

.formbtn {
  border-radius: 5px;
  border-width: 1px;
  border-color:lightgray;
  position: relative;
}


.formbtn::before,
.formbtn::after {
  --scale: 0;
  --tooltip-color: #333;
  --arrow-size: 10px;
  position:absolute;
  content: "";
  top:-.25rem;
  left: 50%;
  transform: translateX(15%) translateY(-6%) scale(var(--scale));
  transition: 120ms;
  transform-origin: left center;
}

.formbtn::before {
  content:attr(data-tooltip);
  background: var(--tooltip-color);
  color:whitesmoke;
  padding: .5rem;
  width: max-content;
  border-radius: .3rem;
  text-align: center;
}

.formbtn:hover:before,
.formbtn:hover::after {
  --scale: 1;
}

.formbtn::after {
  content: "";
  border: var(--arrow-size) solid transparent;
  border-right-color: var(--tooltip-color);
  transform: translateX(130%) translateY(35%) scale(var(--scale));
  transform-origin: right center;
}

.formHeadtxt {
  text-align: center;
}

.services {
  display: flex;
  justify-content: center;
  margin: 30px;
  font-family: 'Raleway', sans-serif;
}

.servTitle{
  display: flex;
  justify-content: center;
}

.servPara{
  max-width: 70%;
  position: relative;
  padding: 5px;
  
}



/* .box {
  position: relative;
  padding: 10px;
  background: rgba(255,255,255,.4);
  box-shadow: 0 8px 10px -6px black;
} */

/* .boxAbout {
  position: relative;
  padding: 10px;
  background: rgba(255,255,255,.4);
  box-shadow: 0 8px 10px -6px black;
  text-align: center;
}*/

  .boxServ {
  position: relative;
  padding: 10px;
  background: rgba(255,255,255,.4);
  box-shadow: 5px 9px 10px -6px rgb(167, 165, 165);
  border: solid rgb(233, 228, 228) 1px;
}  

.aboutTxt {
  margin: 30px;
  max-width: 70%;
  position: relative;
  padding: 5px;
  text-align:justify;
}

.aboutall {
  display: flex;
  justify-content:space-evenly;
  align-items:center;
  font-family: 'Raleway', sans-serif;
}

.nameTitle{
  font-size: 4rem;
}


.aboutPic {
  max-width: 70%;
  margin: 30px;
  min-width: 200px;
  box-shadow: 0 8px 10px -6px rgb(48, 48, 48);
}

.piePic{
 width: 430px;
 height: 430px;
 background-image: url(../src/pie.png);
 background-size: 100%;
 background-repeat: no-repeat;
 z-index: -1;
 position: absolute;
 margin-left: 60%;
 margin-top: 5%;
}



.servRange {
  text-align: center;
}

@media(max-width: 480px){
  .piePic{
    width: 320px;
    height: 320px;
    margin-left: 12%;
    margin-top: 195%;
  }
}

@media (max-width:480px){
  .aboutTxt {
  max-width: 95%;
  }
}

@media (max-width: 480px){
  .aboutall {
    flex-wrap: wrap;
  }
}

@media (min-width: 500px){
  .homeTitle{
  width: 50%;
  font-size: 3rem;
  margin-left: 25%;
  font-family: 'Raleway', sans-serif;
}
}

@media (max-width: 480px){
  .homeTitle{
  width: 80%;
  margin-left: 10%;
  font-family: 'Raleway', sans-serif;

}
}

@media (max-width: 480px){
  .servPara{
  max-width: 98%;
  position: relative;
  padding: 2px;
}
}

@media (max-width: 480px){
  .contactForm {
  margin-left: 5%;
  margin-top: 30px;
  margin-bottom: 10px;
  max-width: 90%;
  padding: 10px;
  font-family: 'Raleway', sans-serif;
}
}

@media(max-width: 480px){
  .aboutPic {
  max-width: 90%;
}
}

@media(max-width: 480px){
  .aboutTitle{
  text-align: left;
  }
}

@media (min-width: 500px){
  .servTwoPara {
  display: flex;
}
}

@media(min-width: 500px){
  .leftPara{
    justify-content: flex-start;
    margin-right: 10px;
  }
}

@media(min-width: 500px){
  .rightPara{
   justify-content: flex-end;
   margin-left: 10px;
  }
}  

.separator {
  border-bottom: solid black 2px;
}

.page {
  position: absolute;
  top: 100px;
  left: 0px;
  right: 0px;
}

ul .indent{
  list-style-type: circle;
}

.indent {
  margin-left: 50px;
}


.impresD {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  margin: 30px;
  font-family: 'Raleway', sans-serif;
  max-width: 40;
  text-align:justify;
  
}

@media(min-width: 500px){
  .impresD {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  margin: 30px;
  font-family: 'Raleway', sans-serif;
  max-width: 40%;
  text-align:justify;
  margin-left: 30%;
  margin-right: 30%;
}
}

@media(min-width: 500px){
.impressT {
  margin-left: 40%;
}
}

@media(max-width: 480px){
  .impresT {
    margin-left: 25%;
  }
}

.datenAll {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  margin: 30px;
  font-family: 'Raleway', sans-serif;
  text-align:justify;
  
}

@media(min-width: 500px){
  .datenAll {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  margin: 30px;
  font-family: 'Raleway', sans-serif;
  text-align:justify;
  max-width: 60%;
  margin-left: 30%;
  margin-right: 30%;
  }
}
@media(max-width: 480px){
  .datenTitel {
    margin-left: -10px;
  }
}
@media(min-width: 500px){
  .datenTitel {
    margin-left: 30%;
  }
}